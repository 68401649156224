// eslint-disable-next-line import/prefer-default-export
const ApiEndpoints = {
  AUTH: {
    LOGIN: '/stakeholders/authenticate',
    REQUEST_RESET_PASSWORD: '/stakeholders/account/reset_password/init',
    CREATE_PASSWORD: '/stakeholders/account/reset_password/finish',
    PRIVACY_POLICY: '/stakeholders/privacy-policy',
    USER_POLICY: '/stakeholders/user-policy',
    ACCEPT_USER_POLICY: '/stakeholders/user-policy',
  },
  INIT: {
    ADMIN_CONFIG: '/core/config/admin_config',
    BUSINESS_TYPES: '/stakeholders/public/business-types?size=1000',
    GET_CITIES: '/core/public/cities',
    THEME: '/core/themes',
    PARTNER_WARDS: '/core/partner-wards',
    DEPARTMENTS: '/core/departments',
    DESIGNATIONS: '/core/designations',
    PICKUP_EXECUTIVE: '/stakeholders/pickup-people',
    TRANSPORTERS: 'core/logistics-transporters',
    VEHICLES: 'core/logistics-vehicle',
  },
  CATALOG: {
    GET_ITEMS: '/catalog/customers/partner-items',
    GET_CATEGORIES: '/catalog/partner/categories',
    GET_LOCATION_ITEMS_PRICE: '/catalog/partner/location-items-price/',
    POST_LOCATION_ITEMS_PRICE: '/catalog/partner/location-items-price',
    ITEM_ID: '/catalog/partner/items',
    DELETE_ITEM: '/catalog/partner/remove-item',
    ITEM_LOCATION_PRICE: '/catalog/partner/item-locations-price',
    GET_UPLOAD_RECORDS: '/catalog/upload-records',
    GET_DOWNLOAD_RECORDS: '/catalog/download-records',
    UPLOAD_SAMPLE: '/catalog/rate-cards/sample',
    SEARCH_ITEM: 'catalog/items/search',
    RATE_CARDS_FILTER: '/catalog/rate-cards/filter',
    IMPORT_RATE_CARDS: '/catalog/import/rate-cards',
  },
  DOCUMENT: {
    DISPLAY: '/core/documents/display',
    SIGNED_URL: '/core/documents/signed-url',
    DELETE_DOC: '/core/documents/remove',
    ATTACH_DOCUMENT: '/core/documents/attach',
    ATTACH_DOCUMENT_CATALOG: '/catalog/documents/attach',
    PROFORMA_INVOICE: '/core/orders/proforma-invoice/pdf',
  },
  USER: {
    ACCOUNT: '/stakeholders/account',
    CHANGE_PASSWORD: '/stakeholders/account/change-password',
  },
  CITY: {},
  LOCATION: {
    GET: '/core/locations',
  },
  CUSTOMERS: {
    GET: '/stakeholders/customers',
    DOWNLOAD: '/stakeholders/download-customers',
    LOGISTICS_VEHICLE: '/core/logistics-vehicle',
    CUSTOMER_ID: '/stakeholders/customers',
    PURCHASE_ORDER: '/core/orders/purchase-orders',
    CUSTOMER_LOCATION: '/core/locations/customers',
    BASIC_INFO: '/stakeholders/partner/basic-info',
    CONTACT_PERSON_INFO: '/stakeholders/partner/update-contact-person',
    CUSTOMER_LOCATION_UPDATE: '/core/locations/customers',
    CUSTOMER_UPDATE_ADDRESS: '/stakeholders/partner/update-address',
    SARVEKSEN_PROFILE_ATTRIBUTES: '/stakeholders/public/profile-form-core-attributes',
    SARVEKSEN_PROFILE_WASTETYPE: '/stakeholders/public/profile-form-waste-type',
    SARVEKSEN_ADD_INFO_GET: '/stakeholders/customers',
    SARVEKSEN_ADD_INFO_PUT: '/stakeholders/customers/additional-info',
    CREATE_NEW_CUSTOMER: '/stakeholders/create-customer',
    FAVORITES_ITEM_LOADTABLE: '/catalog/partner/favourite-items',
    FAVORITES_ITEM_PUT: '/catalog/partner/favourite-item',
    FAVORITES_ITEM_PATNER: '/catalog/customers/partner-items',
    GENERATE_CUSTOMID: '/stakeholders/generate-custom-id',
    TRACK_VISITS: '/core/track-visits',
    ADD_TO_NETWORK: '/stakeholders/customers/add-to-network',
  },
  RECYCLER: {},
  USERS: {
    FETCH: '/stakeholders/system-users',
    CREATE: '/stakeholders/system-users',
    UPDATE: '/stakeholders/system-users',
  },
  DASHBOARD: {
    LOCATIONS: '/core/locations',
    MATERIAL_STOCK_STATS: '/process/material-stock-stats',
    CASH_DASHBOARD: '/core/cash-dashboard',
    STOCK_ADJUSTED_STATS: '/process/stock-adjusted-stats',
    AVAILABLE_STOCK_STATS: '/process/available-stock-stats',
    TREND_STATS: '/core/trend-stats',
    ORDER_DASHBOARD: '/core/order-dashboard',
    WASTE_COLLECTORS_DASHBOARD: '/core/waste-collectors-dashboard',
    MARKETPLACE_STATS: '/marketplace/service/smart-center/stats',
    EPR_ORDER_STATS: '/epr/executionSummary/smart-center/orders',
    EPR_DELIVERY_STATS: '/epr/executionSummary/smart-center/deliveries',
    EPR_INVOICES_STATS: '/epr/executionSummary/smart-center/invoices',
    MATERIAL_TRENDS: '/process/material-trend-stats',
    ORDER_TRENDS: '/core/order-trends',
    MATERIAL_BY_SOURCE: '/process/material-by-source',
    MATERIAL_BY_BUYER: '/process/material-by-buyer',
    PICKUP_EXECUTION_STATS: '/core/pickups-execution-flow',
    PICKUP_ORDER_STATS: '/core/pickups-dashboard',
    BAG_STATS: '/core/bag-pickups',
    BAG_WEIGHT_TREND: '/core/weight-trends',
    USER_WITH_1BAG_TREND: '/core/bags-with-trends',
    BAG_CONTAMINATED_TREND: '/core/contaminated-trends',
    BAG_COMPARISON_OVERVIEW: '/core/bags-trends',
  },
  REPORTS: {
    PARTNER_WARDS: '/core/partner-wards',
    PARTNER_ITEMS: '/catalog/customers/partner-items',
    INWARD_REPORT: '/core/inward-report',
    INWARD_CSV_REPORT: '/core/detailed-inward-report',
    WASTE_COLLECTOR_REPORT: '/core/waste-collector-report',
    WASTE_COLLECTOR_CSV_REPORT: '/core/waste-collector-report-download',
    STOCK_REPORT: '/process/stock-report',
    STOCK_CSV_REPORT: '/process/stock-report-download',
    OPENING_STOCK: '/process/opening-stock',
    PAYMENTS_REPORT: '/core/payments-report',
    SALES_REPORT: '/core/sales-report',
    ATTENDANCE_REPORT: '/core/attendance-report',
    ATTENDANCE_COUNTS: '/core/get-total-count',
    PAYMENT_SUMMERY_REPORT: '/core/payment-summary-report',
    PAYMENT_SUMMERY_CSV_REPORT: '/core/payment-summary-report-download',
    OPENING_BALANCE: '/core/payment-opening-balance',
    ATTENDANCE_EMPLOYEES: '/core/employees',
  },
  CASH: {
    CASH_LOCATION: '/core/cash-management/location-cash-balance',
    CASH_LEDGER: '/core/cash-management/ledger-txns',
    CASH_ADDCASH: '/core/cash-management/add-cash',
    ADVANCE_PAYMENT: '/core/cash-management/advance-payments',
    CUSTOMER_ADVANCE: '/core/cash-management/customer-advance-payments',
  },
  PICKUP: {
    LOCATIONS: '/core/locations',
    TIMESLOTS: '/core/time-slots',
    TIMESLOTS_CAPACITY: '/core/time-slot-capacity',
    ZIPCODES: '/core/location-zipcodes',
    USERS: '/core/location-users',
    CENTER_USERS: '/stakeholders/center-users',
    WARDS: '/core/wards',
  },
  ATTENDANCE: {
    GET: '/core/attendances?size=10000000',
    DEPARTMENT_ATTENDANCE: '/core/department-attendance',
    STATS: '/core/attendance-stats',
  },
  INBOUND: {
    LOCATIONS_BY_BG: '/core/locations/customers',
    GET_ITEMS: '/catalog/items',
    CREATE_PICKUP_ORDER: 'core/customer/business/orders',
  },
  ORDERS: {
    GET_PURCHASE_ORDER: '/core/orders/purchase-orders',
    GET_SALES_ORDER: '/core/orders/sale-orders',
    GET_ORDER: '/core/orders',
    CREATE_ORDERLOGISTICS: '/core/orders/order-logistics',
    PICKUP_ORDERS: '/core/pickup-orders',
    ASSIGN_EXECUTIVE: '/core/orders/assign-executive',
    VERIFY_INVOICE: '/core/order-payments/change-status',
    MAKE_PAYMENT: '/core/order-payments/make-payment',
    UPDATE_ORDER: '/core/orders',
    DELETE_ORDER: '/core/pickup-order',
    PROCESS_STOCK: '/process/stock',
  },
  OUTBOUND: {
    GET_ITEMS: '/catalog/sale-order-items',
    CREATE_OUTBOUND_ORDER: '/core/sale-orders',
  },
  BAGS: {
    BAG_TXNS: '/core/bag-txns',
  },
  BWG: {
    TIME_SLOTS: '/core/trip-time-slots',
    BWG_LIST: '/stakeholders/customers',
    ACTIVATE_CUSTOMER: '/stakeholders/customers/activate-user',
    CUSTOMER_FEE: '/core/bwg/update-customer-fee',
    WARDS: '/core/partner-wards',

    CREATE_AGENCY: '/core/agencies',
    AGENCY_LIST: '/core/agencies',
    AGENCY_UPDATE: '/core/agencies/update',
    AGENCY_DEACTIVATE: '/core/agencies/deactivate',
    AGENCY_ACTIVATE: '/core/agencies/activate',

    CREATE_DRIVER: '/core/drivers/create',
    DRIVER_UPDATE: '/core/drivers/update',
    DRIVER_LIST: '/core/drivers',

    VEHICLES_LIST: '/core/vehicles',
    VEHICLE_UPDATE: '/core/vehicles/update',
    CREATE_VEHICLE: '/core/vehicles/create',
    VEHICLE_COUNT: '/core/vehicles/count',

    BWG_CUST_TINY: '/core/bwg-all-customers',
    ROUTES_CUSTOMERS: '/core/bwg-all-customers',
    CREATE_ROUTE: '/core/routes/create',
    ROUTES_LIST: '/core/routes',
    UPDATE_DRIVER_VEHICLE: '/core/routes/update-driver-vehicle',
    ARCHIVE_ROUTE: '/core/routes/archive',

    CREATE_TRIP: '/core/trips/create-trip',
    TRIPS_LIST: '/core/trips',
    TRANSACTION_LIST: '/core/trip-txns',
    CANCEL_TRIP: '/core/trips/cancel-trip',
    EDIT_TRIP: '/core/trips/assign-driver-vehicle',

    ASSIGN_REMOVE: '/core/routes/update-bwg-list',

    CASH_LIST: '/core/customer-fee-payments',
    PAYMENT_STATS: 'core/customer-fee-payments-stats',

    GET_FEEDBACK_LIST: '/core/customer-feedbacks',
    DOWNLOAD_BWG_CUSTOMERS: '/stakeholders/bwg-customers-download',

    CUSTOMER_CREATE: '/stakeholders/create-bwg-customer',
    CUSTOMER_UPDATE: '/stakeholders/update-bwg-customer',

    REMOVE_AGENCY_WARD: '/core/agencies/remove-ward',
    ADD_AGENCY_WARD: '/core/agencies/add-ward',
    ROUTE_WARDS: '/core/agencies/route-wards',
    GENERATE_QR: '/stakeholders/bwg/generate-qr-codes',

    TXN_REPORT: '/core/reports/bwg-trip-txns',
    TRIP_REPORT: '/core/reports/bwg-trips',
  },
  TRACKING: {
    UPDATE: '/tracking/latlng',
    GET_TRIP: '/tracking/trip',
  },

  SMART_SCAN_DASHBOARD: {
    GET_MATERIALS: '/core/smartscan/materials',
    GET_MATERIAL_TYPES: '/core/smartscan/materialtypes',
    GET_MATERIAL_FORMS: '/core/smartscan/materialforms',
    GET_TOTAL_ITEMS_SCANNED: '/core/smartscan/total-items-scanned',
    GET_DATA_COLLECTED: 'core/smartscan/dashboarddata-quantity-collected',
  },
};

export default ApiEndpoints;
