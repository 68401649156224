export const barChartColors = [
  '#ED9714',
  '#F6E05E',
  '#68D391',
  '#4FD1C5',
  '#63B3ED',
  '#7F9CF5',
  '#F687B3',
  '#F8A9A9',
  '#B4E0E8',
];
export const pieColors = [
  '#EAA9F8',
  '#55D8FE',
  '#F9AECC',
  '#98E0B4',
  '#FFDA83',
  '#89E0D8',
  '#63B3ED',
  '#FF8373',
  '#A3A0FB',
];
export const progressBarColors = [
  '#CBD5E0',
  '#FC8181',
  '#ED9714',
  '#F6E05E',
  '#68D391',
  '#4FD1C5',
  '#63B3ED',
  '#7F9CF5',
  '#B794F4',
];
