/* eslint-disable max-len */
import React from 'react';

export default function TotalCountIcon(props) {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g id="Group 27419">
        <path
          id="Icon.BG"
          d="M17.09 0C20.3534 0 23.5435 0.967708 26.2569 2.78075C28.9703 4.59379 31.0851 7.17074 32.334 10.1857C33.5828 13.2007 33.9096 16.5183 33.2729 19.719C32.6363 22.9197 31.0648 25.8597 28.7572 28.1673C26.4497 30.4748 23.5096 32.0463 20.309 32.683C17.1083 33.3196 13.7907 32.9929 10.7757 31.744C7.76071 30.4952 5.18376 28.3803 3.37072 25.6669C1.55767 22.9535 0.589966 19.7634 0.589966 16.5C0.589966 12.1239 2.32835 7.92709 5.4227 4.83274C8.51705 1.73839 12.7139 0 17.09 0Z"
          fill="#D3FFFB"
        />
        <g id="noun_Stock_4187172">
          <g id="Layer 22">
            <path
              id="Path 48697"
              d="M17.938 17.346V24.122H24.714V17.346H17.938ZM22.173 20.734H20.479C20.3677 20.734 20.2576 20.7121 20.1548 20.6695C20.0521 20.627 19.9587 20.5646 19.88 20.4859C19.8014 20.4073 19.739 20.3139 19.6964 20.2111C19.6539 20.1084 19.632 19.9982 19.632 19.887C19.632 19.7758 19.6539 19.6656 19.6964 19.5629C19.739 19.4601 19.8014 19.3667 19.88 19.2881C19.9587 19.2094 20.0521 19.147 20.1548 19.1045C20.2576 19.0619 20.3677 19.04 20.479 19.04H22.173C22.3976 19.04 22.613 19.1292 22.7719 19.2881C22.9307 19.4469 23.02 19.6624 23.02 19.887C23.02 20.1116 22.9307 20.3271 22.7719 20.4859C22.613 20.6448 22.3976 20.734 22.173 20.734ZM9.46796 17.346V24.122H16.244V17.346H9.46796ZM13.703 20.734H12.009C11.7843 20.734 11.5689 20.6448 11.41 20.4859C11.2512 20.3271 11.162 20.1116 11.162 19.887C11.162 19.6624 11.2512 19.4469 11.41 19.2881C11.5689 19.1292 11.7843 19.04 12.009 19.04H13.703C13.9276 19.04 14.143 19.1292 14.3019 19.2881C14.4607 19.4469 14.55 19.6624 14.55 19.887C14.55 20.1116 14.4607 20.3271 14.3019 20.4859C14.143 20.6448 13.9276 20.734 13.703 20.734ZM13.703 8.87601V15.652H20.479V8.87601H13.703ZM17.938 12.264H16.244C16.0193 12.264 15.8039 12.1748 15.645 12.0159C15.4862 11.8571 15.397 11.6416 15.397 11.417C15.397 11.1924 15.4862 10.9769 15.645 10.8181C15.8039 10.6592 16.0193 10.57 16.244 10.57H17.938C18.1626 10.57 18.378 10.6592 18.5369 10.8181C18.6957 10.9769 18.785 11.1924 18.785 11.417C18.785 11.6416 18.6957 11.8571 18.5369 12.0159C18.378 12.1748 18.1626 12.264 17.938 12.264Z"
              fill="#4FD1C5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
