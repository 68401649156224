import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import Nodata from '../../../assets/img/Nodata.jpg';
import ProgressBar from './ProgressBar';

export default function ItemProgressCard(props) {
  const { filters, setFilters, dateRange, materialTypesData, activeIndex, setActiveIndex } = props;

  const materialTypes = useMemo(() => {
    if (!isEmpty(materialTypesData)) {
      return materialTypesData?.filter((item) => item?.types === filters?.materialSelected);
    }
    return [];
  }, [materialTypesData, filters?.materialSelected]);

  return (
    <>
      {!isEmpty(materialTypes) ? (
        <div>
          {materialTypes?.map((type, index) => (
            <ProgressBar
              index={index}
              type={type}
              filters={filters}
              setFilters={setFilters}
              setActiveIndex={setActiveIndex}
              isActive={index === activeIndex}
              maxCount={materialTypes?.[0]?.count}
            />
          ))}
        </div>
      ) : (
        <div
          style={{ height: '36.5rem' }}
          className="d-flex justify-content-center align-items-center">
          <img src={Nodata} alt="no data" style={{ height: '30.5rem' }} />
        </div>
      )}
    </>
  );
}
