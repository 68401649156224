import React from 'react';
import { Button } from 'react-bootstrap';
import ActiveGo from '../../../assets/svg/dashboardIcons/ActiveGo';
import { progressBarColors } from './DashboardColors';

export default function ProgressBar(props) {
  const { index, setActiveIndex, type, setFilters, isActive, maxCount } = props;

  const styles = {
    active: {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #CACED5',
      borderRadius: '5px',
      opacity: 1,
    },
    inactive: {
      borderBottom: '1px solid #CACED5',
    },
  };

  const getProgressBarColor = () => {
    return progressBarColors[index % progressBarColors?.length];
  };

  return (
    <div
      onClick={() => {
        setActiveIndex(index);
        setFilters((prev) => ({ ...prev, materialTypeSelected: type?.type }));
      }}
      style={{
        width: '100%',
        height: '4.692rem',
        ...(isActive ? styles?.active : styles?.inactive),
      }}
      className="d-flex flex-row justify-content-around align-items-center"
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}>
      <div style={{ width: `80%` }}>
        <div style={{ fontSize: '1.077rem' }}>{type?.type}</div>
        <div
          style={{
            width: '100%',
            backgroundColor: '#e7e4da',
            borderRadius: '100px',
            height: '13px',
            overflow: 'hidden',
            position: 'relative',
          }}>
          <div
            style={{
              right: `${((maxCount - type?.count) / maxCount) * 100}%`,
              transition: '3s',
              // backgroundImage: 'linear-gradient(to right, #666fcc, #9c77d3)',
              backgroundColor: `${getProgressBarColor()}`,
              borderRadius: '100px',
              height: '100%',
              width: '100%',
              position: 'absolute',
            }}
          />
        </div>
      </div>
      <div className="font-semibold" style={{ fontSize: '1.077rem', color: '#5B7583' }}>
        {type?.count}
      </div>
      <div>
        <ActiveGo />
      </div>
    </div>
  );
}
