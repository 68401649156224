import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useAppConfig, useAsyncEffect } from '../../../hooks';
import { useDashboardData } from '../../../hooks/hooks';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import { FormField, MpDatePicker } from '../../../shared/components';
import AllMaterialsLayout from './AllMaterialsLayout';
import ConveyorBelt from './ConveyorBelt';
import {
  // useLazyGetSmartScanTotalItemsScannedQuery,
  useLazyGetSmartScanDataCollectedQuery,
  useLazyGetSmartScanMaterialFormsQuery,
  useLazyGetSmartScanMaterialTypesQuery,
  useLazyGetSmartScanMaterialsQuery,
} from './Smartscan.api';

export default function DashboardBody(props) {
  const today = moment();
  const isMobile = useCheckMobileScreen();
  const { locations, theme } = useAppConfig();
  const [{ availableStockStatsData }, { doFetchAvailableStockStats }] = useDashboardData();

  const locations1 = useMemo(
    () => [
      // ...locations,
      { id: 62, name: 'Ward 174 HSR Layout' },
    ],
    [locations]
  );

  // const [
  //   getSmartScanTotalItemsScanned,
  //   { data: totalItemsScanned, isLoading: isItemsScannedCountLoading },
  // ] = useLazyGetSmartScanTotalItemsScannedQuery();

  const [
    getSmartScanDataCollected,
    { data: dataCollected, isLoading: isDataColletedLoading },
  ] = useLazyGetSmartScanDataCollectedQuery();

  const [
    getSmartScanMaterials,
    { data: materials, isLoading: isMaterialsLoading },
  ] = useLazyGetSmartScanMaterialsQuery();

  const [
    getSmartScanMaterialTypes,
    { data: materialTypesData },
  ] = useLazyGetSmartScanMaterialTypesQuery();

  const [
    getSmartScanMaterialForms,
    { data: materialFormsData },
  ] = useLazyGetSmartScanMaterialFormsQuery();

  const [activeButton, setActiveButton] = useState('TODAY');
  const [dateRange, setDateRange] = useState({
    fromDate: today.startOf('day').toISOString(),
    toDate: today.endOf('day').toISOString(),
  });
  const setDateFilter = (selectedDates) => {
    setActiveButton('RANGE');
    setDateRange({ fromDate: selectedDates[0], toDate: selectedDates[1] });
  };
  const [filters, setFilters] = useState({
    materialSelected: '',
    materialTypeSelected: '',
    locationId: '',
  });
  const [barChartData, setBarChartData] = useState([]);

  useAsyncEffect(async () => {
    await doFetchAvailableStockStats();
    await getSmartScanMaterials(dateRange);
  }, [filters?.locationId, dateRange]);

  const dryWasteRejected = useMemo(
    () => availableStockStatsData?.filter((item) => item?.itemId === 'SKUMIS50101008'),
    [availableStockStatsData]
  );

  const onClickToday = () => {
    setActiveButton('TODAY');
    onClickToday;
    setDateRange({
      fromDate: today.startOf('day').toISOString(),
      toDate: today.endOf('day').toISOString(),
    });
  };

  useAsyncEffect(async () => {
    // await getSmartScanTotalItemsScanned(dateRange);
    await getSmartScanDataCollected(dateRange);
    await getSmartScanMaterialTypes({ ...dateRange });
  }, [dateRange]);

  // useAsyncEffect(async () => {
  //   await getSmartScanMaterialTypes({ type: filters?.materialSelected, ...dateRange });
  // }, [filters?.materialSelected, dateRange]);

  useAsyncEffect(async () => {
    await getSmartScanMaterialForms({
      type1: filters?.materialSelected,
      type2: filters?.materialTypeSelected,
      ...dateRange,
    });
  }, [filters?.materialSelected, filters?.materialTypeSelected, dateRange]);

  return (
    <div>
      {/* Filters */}
      <Row className="mb-3 align-items-center">
        <Form.Group as={Col} lg={2} md={2} xs={6} className="pr-0 pr-lg-2" controlId="center">
          <FormField label="Centers">
            {/* <DropdownSelect
              isClearable
              value={62}
              options={[..._.sortBy(locations1, ['name'])]}
              isMulti={false}
              isDisabled
              placeholder="All Centers"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={(option) => setFilters({ ...filters, locationId: option?.id })}
            /> */}
            <h4>Ward 174 HSR Layout</h4>
          </FormField>
          {/* <h4>Ward 174 HSR Layout</h4> */}
        </Form.Group>

        <Form.Group as={Col} lg={3} xs={7} className="pr-0 pr-lg-2" controlId="targetDate">
          <FormField label="Target Date">
            <MpDatePicker
              id="orderDate"
              initialSettings={{
                autoUpdateInput: false,
                locale: {
                  cancelLabel: 'Reset',
                },
                alwaysShowCalendars: true,
                maxDate: new Date(),
              }}
              predefinedRange
              inputClass="date-range-field"
              startDate={dateRange.fromDate}
              endDate={dateRange.toDate}
              placeholder="Select date range"
              onApply={({ startDate, endDate }, $event) => {
                setDateFilter([startDate, endDate]);
              }}
              onCancel={(event, picker) => {
                onClickToday();
              }}
            />
          </FormField>
        </Form.Group>
        <Form.Group as={Col} lg={3} xs={7} className="pr-0 pr-lg-2" controlId="today">
          <Button
            className="pl-5 pr-5 pt-2 pb-2 mt-4"
            onClick={onClickToday}
            disabled={false}
            style={{ backgroundColor: '#328E69' }}>
            Today
          </Button>
        </Form.Group>
      </Row>
      {/* Conveyor Belt Layout */}
      <ConveyorBelt
        dateRange={dateRange}
        filters={filters}
        materials={materials}
        materialTypesData={materialTypesData}
        // totalItemsScanned={totalItemsScanned}
        dataCollected={dataCollected}
      />
      {/* All Materials Layout */}
      <AllMaterialsLayout
        dateRange={dateRange}
        filters={filters}
        materials={materials}
        materialTypesData={materialTypesData}
        materialFormsData={materialFormsData}
        setFilters={setFilters}
        dryWasteRejected={dryWasteRejected}
      />
    </div>
  );
}
