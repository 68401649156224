import classNames from 'classnames';
import React, { Suspense } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DashboardBody from '../../features/Dashboard/SmartScanDashboardFeatures/DashboardBody';
import './DashboardPage.scss';
import PageContainer from '../../hoc/PageContainer';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { Loader } from '../../shared/components';

export default function SmartScanDashboard(props) {
  const isMobile = useCheckMobileScreen();

  return (
    <Suspense fallback={<Loader />}>
      <PageContainer className="DashboardPage smartscan pt-0">
        <Container fluid="true" className="ml-1 mr-1 pl-lg-3 pr-lg-3">
          {/* Header */}
          <Row className="mb-3 pt-4">
            <Col xs="auto">
              <p
                className={classNames('mb-3', {
                  'text-align-center': isMobile,
                })}>
                <span className="font-semibold" style={{ fontSize: '1.231rem', color: '#27353C' }}>
                  Waste Analytics Dashboard
                </span>
              </p>
            </Col>
          </Row>
          <DashboardBody />
        </Container>
      </PageContainer>
    </Suspense>
  );
}
