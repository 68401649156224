/* eslint-disable max-len */
import React from 'react';

export default function ItemsScanned() {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 0C19.7634 0 22.9535 0.967708 25.6669 2.78075C28.3803 4.59379 30.4952 7.17074 31.744 10.1857C32.9929 13.2007 33.3196 16.5183 32.683 19.719C32.0463 22.9197 30.4748 25.8597 28.1673 28.1673C25.8597 30.4748 22.9197 32.0463 19.719 32.683C16.5183 33.3196 13.2007 32.9929 10.1857 31.744C7.17074 30.4952 4.59379 28.3803 2.78075 25.6669C0.967708 22.9535 0 19.7634 0 16.5C0 12.1239 1.73839 7.92709 4.83274 4.83274C7.92709 1.73839 12.1239 0 16.5 0Z"
        fill="#EAF1FF"
      />
      <path
        d="M14.545 19.8H19.414C19.558 19.7992 19.6959 19.7417 19.7978 19.6398C19.8996 19.538 19.9572 19.4001 19.958 19.256V16.809C19.9572 16.665 19.8996 16.5271 19.7978 16.4252C19.6959 16.3234 19.558 16.2658 19.414 16.265H14.545C14.401 16.2658 14.2631 16.3234 14.1612 16.4252C14.0594 16.5271 14.0018 16.665 14.001 16.809V19.256C14.0003 19.3276 14.0139 19.3987 14.0411 19.465C14.0682 19.5313 14.1082 19.5915 14.1588 19.6422C14.2095 19.6928 14.2697 19.7329 14.336 19.76C14.4023 19.7871 14.4734 19.8007 14.545 19.8Z"
        fill="#63B3ED"
      />
      <path
        d="M14.545 15.41H17.731C17.875 15.4092 18.013 15.3516 18.1148 15.2498C18.2167 15.148 18.2742 15.01 18.275 14.866V12.743C18.2742 12.599 18.2167 12.4611 18.1148 12.3592C18.013 12.2574 17.875 12.1998 17.731 12.199H14.545C14.401 12.1998 14.2631 12.2574 14.1612 12.3592C14.0594 12.4611 14.0018 12.599 14.001 12.743V14.867C14.0021 15.0109 14.0597 15.1485 14.1616 15.2502C14.2634 15.3518 14.4011 15.4092 14.545 15.41Z"
        fill="#63B3ED"
      />
      <path
        d="M9.971 10.587C9.96964 10.521 9.98164 10.4553 10.0063 10.3941C10.0309 10.3328 10.0677 10.2771 10.1144 10.2304C10.1611 10.1837 10.2168 10.1469 10.2781 10.1223C10.3393 10.0976 10.405 10.0856 10.471 10.087H13.257V8.115H10.513C9.85206 8.10994 9.21614 8.36745 8.74494 8.83096C8.27375 9.29447 8.00581 9.92607 8 10.587V13.372H9.971V10.587Z"
        fill="#63B3ED"
      />
      <path
        d="M22.602 8.11499H19.827V10.086H22.587C22.6544 10.084 22.7216 10.0954 22.7846 10.1195C22.8476 10.1436 22.9053 10.1799 22.9542 10.2264C23.0031 10.2729 23.0423 10.3286 23.0695 10.3903C23.0968 10.452 23.1116 10.5185 23.113 10.586V13.372H25.084V10.587C25.0844 10.2614 25.0204 9.93896 24.8957 9.63821C24.771 9.33746 24.588 9.06435 24.3573 8.83459C24.1266 8.60484 23.8528 8.42297 23.5515 8.29948C23.2503 8.17598 22.9276 8.11328 22.602 8.11499Z"
        fill="#63B3ED"
      />
      <path
        d="M23.112 21.371C23.1134 21.437 23.1014 21.5027 23.0767 21.5639C23.0521 21.6252 23.0153 21.6809 22.9686 21.7276C22.9219 21.7743 22.8662 21.8111 22.8049 21.8357C22.7437 21.8603 22.678 21.8724 22.612 21.871H19.827V23.883H22.611C23.2719 23.8772 23.9035 23.6092 24.367 23.138C24.8305 22.6669 25.0881 22.0309 25.083 21.37V18.628H23.112V21.371Z"
        fill="#63B3ED"
      />
      <path
        d="M10.472 21.872C10.406 21.8734 10.3403 21.8614 10.2791 21.8367C10.2178 21.8121 10.1621 21.7753 10.1154 21.7286C10.0687 21.6819 10.0319 21.6262 10.0073 21.5649C9.98264 21.5037 9.97064 21.438 9.972 21.372V18.628H8V21.371C8.0042 22.0362 8.2703 22.6729 8.74068 23.1433C9.21105 23.6137 9.84781 23.8798 10.513 23.884H13.257V21.872H10.472Z"
        fill="#63B3ED"
      />
    </svg>
  );
}
