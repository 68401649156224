/* eslint-disable max-len */
import { sumBy } from 'lodash';
import React from 'react';
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import './ScanPieChart.scss';

export const ScanPieChart = ({ chartData, colorList, innerValue, label }) => {
  const hasData = sumBy(chartData, 'value') > 0;
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
      return (
        <div className="bg-white p-3 shadow">
          {payload.map((ele, index) => (
            <>
              <small key={index} className="text-secondary">
                {ele.name} : {ele.value}
              </small>
              <br />
            </>
          ))}
        </div>
      );
    }
    return null;
  };

  const RenderLegend = (props) => {
    const { payload } = props;

    return (
      <div
        className="d-flex flex-wrap px-5 pt-3"
        style={{ height: '15rem', 'overflow-y': 'scroll' }}>
        {payload.map((entry, index) => (
          <div className="d-flex pb-3 pr-2">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none">
                <path
                  d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z"
                  fill={entry?.color}
                />
              </svg>
            </div>

            <div className="mx-2" key={`item-${index}`}>
              <div style={{ color: '#5B7583', fontFamily: 'font-medium', fontSize: '1.23077rem' }}>
                {entry?.payload?.value}
              </div>
              <div style={{ color: '#5B7583', fontSize: '1.07692rem' }}>{entry.value}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={hasData ? chartData : [{ name: 'No Data', value: 0.01 }]}
          // cornerRadius="80%"
          innerRadius="0%"
          outerRadius="80%"
          paddingAngle={1}
          dataKey="value">
          {chartData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={hasData ? colorList[index % colorList.length] : '#dcdcdc'}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip active={false} payload={[]} label="" />} />
        <Legend
          layout="horizontal"
          verticalAlign="bottom"
          align="center"
          // iconType="circle"
          // margin={{ bottom: 3 }}
          content={RenderLegend}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
