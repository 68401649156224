/* eslint-disable max-len */
import React from 'react';

export default function QtyCollectedIcon(props) {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g id="Group 27416">
        <path
          id="Icon.BG"
          d="M16.5 0C19.7634 0 22.9535 0.967708 25.6669 2.78075C28.3803 4.59379 30.4952 7.17074 31.744 10.1857C32.9929 13.2007 33.3196 16.5183 32.683 19.719C32.0463 22.9197 30.4748 25.8597 28.1673 28.1673C25.8597 30.4748 22.9197 32.0463 19.719 32.683C16.5183 33.3196 13.2007 32.9929 10.1857 31.744C7.17074 30.4952 4.59379 28.3803 2.78075 25.6669C0.967708 22.9535 0 19.7634 0 16.5C0 12.1239 1.73839 7.92709 4.83274 4.83274C7.92709 1.73839 12.1239 0 16.5 0Z"
          fill="#FFC6C3"
        />
        <g id="noun_Stock_4187172">
          <g id="Layer 22">
            <path
              id="Path 48697"
              d="M17.348 17.346V24.122H24.124V17.346H17.348ZM21.583 20.734H19.889C19.7778 20.734 19.6676 20.7121 19.5649 20.6695C19.4621 20.627 19.3687 20.5646 19.2901 20.4859C19.2114 20.4073 19.149 20.3139 19.1065 20.2111C19.0639 20.1084 19.042 19.9982 19.042 19.887C19.042 19.7758 19.0639 19.6656 19.1065 19.5629C19.149 19.4601 19.2114 19.3667 19.2901 19.2881C19.3687 19.2094 19.4621 19.147 19.5649 19.1045C19.6676 19.0619 19.7778 19.04 19.889 19.04H21.583C21.8076 19.04 22.0231 19.1292 22.1819 19.2881C22.3408 19.4469 22.43 19.6624 22.43 19.887C22.43 20.1116 22.3408 20.3271 22.1819 20.4859C22.0231 20.6448 21.8076 20.734 21.583 20.734ZM8.87799 17.346V24.122H15.654V17.346H8.87799ZM13.113 20.734H11.419C11.1944 20.734 10.9789 20.6448 10.8201 20.4859C10.6612 20.3271 10.572 20.1116 10.572 19.887C10.572 19.6624 10.6612 19.4469 10.8201 19.2881C10.9789 19.1292 11.1944 19.04 11.419 19.04H13.113C13.3376 19.04 13.5531 19.1292 13.7119 19.2881C13.8708 19.4469 13.96 19.6624 13.96 19.887C13.96 20.1116 13.8708 20.3271 13.7119 20.4859C13.5531 20.6448 13.3376 20.734 13.113 20.734ZM13.113 8.87601V15.652H19.889V8.87601H13.113ZM17.348 12.264H15.654C15.4294 12.264 15.2139 12.1748 15.0551 12.0159C14.8962 11.8571 14.807 11.6416 14.807 11.417C14.807 11.1924 14.8962 10.9769 15.0551 10.8181C15.2139 10.6592 15.4294 10.57 15.654 10.57H17.348C17.5726 10.57 17.7881 10.6592 17.9469 10.8181C18.1058 10.9769 18.195 11.1924 18.195 11.417C18.195 11.6416 18.1058 11.8571 17.9469 12.0159C17.7881 12.1748 17.5726 12.264 17.348 12.264Z"
              fill="#D85D56"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
