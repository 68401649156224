/* eslint-disable max-len */
import React from 'react';

export default function FlowRate() {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5001 0C19.7635 0 22.9536 0.967708 25.667 2.78075C28.3804 4.59379 30.4952 7.17074 31.7441 10.1857C32.9929 13.2007 33.3197 16.5183 32.683 19.719C32.0464 22.9197 30.4749 25.8597 28.1673 28.1673C25.8598 30.4748 22.9197 32.0463 19.7191 32.683C16.5184 33.3196 13.2008 32.9929 10.1858 31.744C7.1708 30.4952 4.59386 28.3803 2.78081 25.6669C0.967769 22.9535 6.10352e-05 19.7634 6.10352e-05 16.5C6.10352e-05 12.1239 1.73845 7.92709 4.8328 4.83274C7.92715 1.73839 12.124 0 16.5001 0Z"
        fill="#FEF1CD"
      />
      <path
        d="M17.4611 17.461V25.151H25.1511V17.461H17.4611ZM22.2611 21.305H20.3441C20.0892 21.305 19.8448 21.2038 19.6645 21.0235C19.4843 20.8433 19.3831 20.5989 19.3831 20.344C19.3831 20.0891 19.4843 19.8447 19.6645 19.6645C19.8448 19.4843 20.0892 19.383 20.3441 19.383H22.2661C22.5209 19.383 22.7654 19.4843 22.9456 19.6645C23.1258 19.8447 23.2271 20.0891 23.2271 20.344C23.2271 20.5989 23.1258 20.8433 22.9456 21.0235C22.7654 21.2038 22.5209 21.305 22.2661 21.305H22.2611ZM7.85106 17.461V25.151H15.5391V17.461H7.85106ZM12.6511 21.305H10.7341C10.4792 21.305 10.2348 21.2038 10.0545 21.0235C9.87431 20.8433 9.77306 20.5989 9.77306 20.344C9.77306 20.0891 9.87431 19.8447 10.0545 19.6645C10.2348 19.4843 10.4792 19.383 10.7341 19.383H12.6511C12.9059 19.383 13.1504 19.4843 13.3306 19.6645C13.5108 19.8447 13.6121 20.0891 13.6121 20.344C13.6121 20.5989 13.5108 20.8433 13.3306 21.0235C13.1504 21.2038 12.9059 21.305 12.6511 21.305ZM12.6511 7.85101V15.539H20.3391V7.85101H12.6511ZM17.4511 11.695H15.5391C15.2842 11.695 15.0398 11.5938 14.8595 11.4135C14.6793 11.2333 14.5781 10.9889 14.5781 10.734C14.5781 10.4791 14.6793 10.2347 14.8595 10.0545C15.0398 9.87426 15.2842 9.77301 15.5391 9.77301H17.4611C17.7159 9.77301 17.9604 9.87426 18.1406 10.0545C18.3208 10.2347 18.4221 10.4791 18.4221 10.734C18.4221 10.9889 18.3208 11.2333 18.1406 11.4135C17.9604 11.5938 17.7159 11.695 17.4611 11.695H17.4511Z"
        fill="#E09D00"
      />
    </svg>
  );
}
