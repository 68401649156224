import { orderBy, sumBy } from 'lodash';
import React, { useMemo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import BottleIcon from '../../../assets/svg/dashboardIcons/BottleIcon';
import FlowRate from '../../../assets/svg/dashboardIcons/FlowRate';
import ItemsScanned from '../../../assets/svg/dashboardIcons/ItemsScanned';
import RunTime from '../../../assets/svg/dashboardIcons/RunTime';
import Tickmark from '../../../assets/svg/dashboardIcons/Tickmark';
import DetailsCard from './DetailsCard';

export default function ConveyorBelt(props) {
  const {
    dateRange,
    filters,
    materials,
    totalItemsScanned,
    dataCollected,
    materialTypesData,
  } = props;
  const totalCount = sumBy(materials, 'count');

  const commonItem = useMemo(
    () => orderBy(materialTypesData, ['count'], ['desc'])?.[0]?.type ?? '-',
    [materialTypesData]
  );

  return (
    <>
      {/* Conveyor Belt Layout */}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <p className="font-semibold" style={{ fontSize: '1.077rem', color: '#323232' }}>
            Conveyor belt
          </p>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          <DetailsCard
            iconComponent={<ItemsScanned />}
            heading="Items Scanned"
            content={totalCount}
          />
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          <DetailsCard iconComponent={<BottleIcon />} heading="Regular Item" content={commonItem} />
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          <DetailsCard iconComponent={<RunTime />} heading="Run Time" content="8 Hr. 15 min" />
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          <DetailsCard
            iconComponent={<FlowRate />}
            heading="Flow Rate"
            content={
              totalCount === 0
                ? `0 Items / Mint.`
                : `${(totalCount / (8.25 * 60)).toFixed(0)} Items / Mint.`
            }
          />
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          <Row className=" mb-2">
            <Col>
              <div className="font-semibold" style={{ fontSize: '0.769rem', color: '#000000' }}>
                Conveyor Belt
              </div>
              <Button
                style={{
                  backgroundColor: '#FFFFFF',
                  boxShadow: '0px 2px 6px #0000000A',
                  border: '1px solid #CACED5',
                  borderRadius: '6px',
                  opacity: 1,
                  color: '#5B7583',
                  marginTop: '2px',
                }}>
                <Tickmark style={{ marginRight: '2px', marginBottom: '0.95px' }} />
                <span>ON</span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
