import { ApiEndpoints } from '../../../shared/constants';
import { backendApi } from '../../../store/apis/base.config';

export const smartScanDashboardAPI = backendApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getSmartScanMaterials: builder.query({
      query: (_params) => ({
        url: ApiEndpoints.SMART_SCAN_DASHBOARD.GET_MATERIALS,
        params: _params,
      }),
    }),
    getSmartScanMaterialTypes: builder.query({
      query: (_params) => ({
        url: ApiEndpoints.SMART_SCAN_DASHBOARD.GET_MATERIAL_TYPES,
        params: _params,
      }),
    }),
    getSmartScanMaterialForms: builder.query({
      query: (_params) => ({
        url: ApiEndpoints.SMART_SCAN_DASHBOARD.GET_MATERIAL_FORMS,
        params: _params,
      }),
    }),
    getSmartScanTotalItemsScanned: builder.query({
      query: (_params) => ({
        url: ApiEndpoints.SMART_SCAN_DASHBOARD.GET_TOTAL_ITEMS_SCANNED,
        params: _params,
      }),
    }),
    getSmartScanDataCollected: builder.query({
      query: (_params) => ({
        url: ApiEndpoints.SMART_SCAN_DASHBOARD.GET_DATA_COLLECTED,
        params: _params,
      }),
    }),
  }),
});

export const {
  useLazyGetSmartScanMaterialsQuery,
  useLazyGetSmartScanMaterialTypesQuery,
  useLazyGetSmartScanMaterialFormsQuery,
  useLazyGetSmartScanTotalItemsScannedQuery,
  useLazyGetSmartScanDataCollectedQuery,
} = smartScanDashboardAPI;
