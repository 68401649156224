/* eslint-disable max-len */
import React from 'react';

export default function Tickmark(props) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        id="Icon awesome-check-circle"
        d="M11.575 6.13399C11.5752 7.19256 11.2615 8.22741 10.6735 9.10767C10.0855 9.98794 9.24972 10.6741 8.27179 11.0793C7.29386 11.4845 6.21772 11.5907 5.17946 11.3843C4.14121 11.1779 3.18748 10.6682 2.43889 9.91978C1.6903 9.17133 1.18047 8.21769 0.973884 7.17948C0.767295 6.14127 0.873224 5.06511 1.27828 4.0871C1.68333 3.10909 2.3693 2.27316 3.24946 1.68503C4.12961 1.0969 5.16441 0.78299 6.22297 0.78299C7.64224 0.782989 9.00339 1.34672 10.0071 2.3502C11.0107 3.35368 11.5747 4.71472 11.575 6.13399ZM5.60897 8.96799L9.57497 4.99699C9.60703 4.96495 9.63245 4.92691 9.6498 4.88505C9.66714 4.84318 9.67607 4.79831 9.67607 4.75299C9.67607 4.70767 9.66714 4.6628 9.6498 4.62093C9.63245 4.57907 9.60703 4.54103 9.57497 4.50899L9.08597 4.01999C9.05394 3.98794 9.0159 3.96251 8.97403 3.94517C8.93217 3.92782 8.88729 3.91889 8.84197 3.91889C8.79666 3.91889 8.75178 3.92782 8.70992 3.94517C8.66805 3.96251 8.63001 3.98794 8.59797 4.01999L5.35997 7.25899L3.84797 5.74699C3.81594 5.71494 3.7779 5.68951 3.73603 5.67217C3.69416 5.65482 3.64929 5.64589 3.60397 5.64589C3.55866 5.64589 3.51378 5.65482 3.47192 5.67217C3.43005 5.68951 3.39201 5.71494 3.35997 5.74699L2.87197 6.23499C2.83992 6.26703 2.8145 6.30507 2.79715 6.34693C2.77981 6.3888 2.77088 6.43367 2.77088 6.47899C2.77088 6.52431 2.77981 6.56918 2.79715 6.61105C2.8145 6.65291 2.83992 6.69095 2.87197 6.72299L5.11597 8.96799C5.14801 9.00004 5.18605 9.02547 5.22792 9.04281C5.26978 9.06016 5.31466 9.06909 5.35997 9.06909C5.40529 9.06909 5.45016 9.06016 5.49203 9.04281C5.5339 9.02547 5.57194 9.00004 5.60397 8.96799H5.60897Z"
        fill="#328E69"
      />
    </svg>
  );
}
