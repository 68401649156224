/* eslint-disable max-len */
import React from 'react';

export default function RunTime() {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 27382">
        <path
          id="Icon.BG"
          d="M16.501 0C19.7644 0 22.9545 0.967708 25.6679 2.78075C28.3814 4.59379 30.4962 7.17074 31.7451 10.1857C32.9939 13.2007 33.3207 16.5183 32.684 19.719C32.0473 22.9197 30.4759 25.8597 28.1683 28.1673C25.8607 30.4748 22.9207 32.0463 19.72 32.683C16.5193 33.3196 13.2017 32.9929 10.1868 31.744C7.17178 30.4952 4.59483 28.3803 2.78179 25.6669C0.968746 22.9535 0.0010376 19.7634 0.0010376 16.5C0.0010376 12.1239 1.73943 7.92709 4.83378 4.83274C7.92813 1.73839 12.125 0 16.501 0Z"
          fill="#D3FFFB"
        />
        <g id="Group 26893">
          <path
            id="Path 48693"
            d="M20.453 8.763L19.6 7.909C21.2914 8.52296 22.7644 9.62173 23.8349 11.068C24.9054 12.5143 25.526 14.244 25.619 16.041H27.654L24.236 19.458L20.819 16.041H22.784C22.6941 14.8279 22.2565 13.6664 21.5237 12.6954C20.791 11.7245 19.794 10.9852 18.652 10.566L20.452 8.766L20.453 8.763Z"
            fill="#4FD1C5"
          />
          <path
            id="Path 48694"
            d="M22.434 18.651C22.0149 19.7929 21.2756 20.7898 20.3046 21.5224C19.3336 22.255 18.1721 22.6923 16.959 22.782V20.818L13.541 24.235L16.959 27.653V25.618C18.7561 25.525 20.4858 24.9044 21.932 23.8336C23.3782 22.7629 24.4766 21.2896 25.09 19.598L24.236 20.453L22.436 18.653L22.434 18.651Z"
            fill="#4FD1C5"
          />
          <path
            id="Path 48695"
            d="M14.349 22.434C13.2071 22.015 12.2101 21.2757 11.4775 20.3047C10.7448 19.3337 10.3076 18.1721 10.218 16.959H12.182L8.76404 13.542L5.34704 16.959H7.38204C7.47546 18.756 8.09626 20.4857 9.16691 21.9319C10.2376 23.3782 11.7106 24.477 13.402 25.091L12.548 24.237L14.348 22.437L14.349 22.434Z"
            fill="#4FD1C5"
          />
          <path
            id="Path 48696"
            d="M10.567 14.349C10.9861 13.2068 11.7255 12.2095 12.6967 11.4767C13.6679 10.7439 14.8297 10.3065 16.043 10.217V12.186L19.46 8.76798L16.043 5.34698V7.38598C14.246 7.4794 12.5164 8.10021 11.0701 9.17086C9.62383 10.2415 8.52507 11.7145 7.91104 13.406L8.76504 12.552L10.565 14.352L10.567 14.349Z"
            fill="#4FD1C5"
          />
        </g>
      </g>
    </svg>
  );
}
