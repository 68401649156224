/* eslint-disable max-len */
import React from 'react';

export default function ActiveGo(props) {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        id="Polygon 11"
        d="M6.93301 4.2C7.0572 4.29315 7.15801 4.41393 7.22743 4.55279C7.29686 4.69164 7.33301 4.84476 7.33301 5C7.33301 5.15525 7.29686 5.30836 7.22743 5.44721C7.15801 5.58607 7.0572 5.70685 6.93301 5.8L1.60001 9.8C1.45144 9.91143 1.27477 9.97928 1.08981 9.99596C0.904852 10.0126 0.7189 9.97748 0.552794 9.89443C0.386688 9.81137 0.246991 9.68371 0.149357 9.52573C0.0517225 9.36775 7.62939e-06 9.18571 7.62939e-06 9L7.62939e-06 1C7.62939e-06 0.814289 0.0517225 0.632245 0.149357 0.474269C0.246991 0.316293 0.386688 0.188626 0.552794 0.105573C0.7189 0.0225204 0.904852 -0.0126368 1.08981 0.00404116C1.27477 0.0207191 1.45144 0.0885733 1.60001 0.2L6.93301 4.2Z"
        fill="#5B7583"
      />
    </svg>
  );
}
