import { isEmpty, sumBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Nodata from '../../../assets/img/Nodata.jpg';
import FlowRate from '../../../assets/svg/dashboardIcons/FlowRate';
import QtyCollectedIcon from '../../../assets/svg/dashboardIcons/QtyCollectedIcon';
import TotalCountIcon from '../../../assets/svg/dashboardIcons/TotalCountIcon';
import { barChartColors, pieColors } from './DashboardColors';
import DetailsCard from './DetailsCard';
import ItemProgressCard from './ItemProgressCard';
import { ScanPieChart } from './PieChart';

export default function AllMaterialsLayout(props) {
  const {
    dateRange,
    filters,
    materials,
    setFilters,
    materialTypesData,
    materialFormsData,
    dryWasteRejected,
  } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  const materialsData = useMemo(() => {
    if (isEmpty(materials)) {
      return [
        { type: 'Plastics', count: 0 },
        { type: 'Fiber', count: 0 },
        { type: 'Metals', count: 0 },
        { type: 'Glass', count: 0 },
        { type: 'Rubber', count: 0 },
        { type: 'Leather', count: 0 },
        { type: 'Wood', count: 0 },
        { type: 'Textiles', count: 0 },
        { type: 'Inorganic Waste', count: 0 },
      ];
    }
    setFilters((prev) => ({ ...prev, materialSelected: materials?.[0]?.type }));
    setActiveIndex(0);
    return materials;
  }, [materials]);

  const pieChartData = useMemo(() => {
    if (!isEmpty(materialFormsData)) {
      return materialFormsData?.map((form) => ({ name: form?.type, value: form?.count }));
    }
    return [];
  }, [materialFormsData]);

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <p className="font-semibold" style={{ fontSize: '1.077rem', color: '#323232' }}>
            All Materials
          </p>
        </Col>
        <Col>
          <Card style={{ borderRadius: '0.5rem' }}>
            <Row
              style={{
                paddingTop: '2.154rem',
                paddingRight: '1.846rem',
                paddingLeft: '1.231rem',
                paddingBottom: '3.423rem',
              }}>
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div
                  style={{
                    height: '20vh',
                    borderRadius: '0.5rem',
                    width: '100%',
                  }}>
                  <ResponsiveContainer width="100%" height="105%">
                    <BarChart data={materialsData}>
                      <CartesianGrid strokeDasharray="1" vertical={false} />
                      <Bar
                        dataKey="count"
                        onClick={(e) => {
                          setFilters((prev) => ({
                            ...prev,
                            materialSelected: e?.type,
                            // materialTypeSelected: '',
                          }));
                          setActiveIndex(0);
                        }}
                        barSize={55}
                        margin={{
                          top: 10,
                          right: 10,
                          left: 10,
                          bottom: 10,
                        }}
                        fillOpacity="0.7"
                        radius={[8, 8, 8, 8]}>
                        {materialsData.map((entry, index) => (
                          <Cell
                            cursor="pointer"
                            fill={barChartColors[index]}
                            key={`cell-${index}`}
                          />
                        ))}
                      </Bar>
                      <Tooltip cursor={false} formatter={(value) => `${value.toFixed()}`} />
                      <XAxis axisLine={false} dataKey="type" tickLine={false} tickMargin={6} />
                      <YAxis
                        tickFormatter={(e) => `${e}`}
                        width={80}
                        axisLine={false}
                        tickLine={false}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Card
                  style={{
                    borderRadius: '0.5rem',
                    minWidth: '20vw',
                  }}>
                  <Row style={{ minHeight: '15.462rem', margin: 0, paddingLeft: '3%' }}>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      className="d-flex  align-items-center p-0 m-0 ">
                      <DetailsCard
                        iconComponent={<QtyCollectedIcon />}
                        heading="Qty. Collected"
                        content="14,976 kg"
                      />
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      className="d-flex  align-items-center p-0 m-0 ">
                      <DetailsCard
                        iconComponent={<TotalCountIcon />}
                        heading="Total Count of Items"
                        content="1,24,800 Nos."
                      />
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      className="d-flex  align-items-center p-0 m-0 ">
                      <DetailsCard
                        iconComponent={<FlowRate />}
                        heading="Dry Waste Rejected"
                        content={`${sumBy(dryWasteRejected, 'stock').toFixed(2)} Kg`}
                      />
                    </Col>
                    {/* <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      className="d-flex  align-items-center p-0 m-0 ">
                      <DetailsCard
                        iconComponent={<LandfillIcon />}
                        heading="Landfill"
                        content="72 kg"
                      />
                    </Col> */}
                  </Row>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mt-2">
                <p className="font-semibold" style={{ fontSize: '1.077rem', color: '#323232' }}>
                  Material Type Numbers
                </p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mt-2">
                <Row className="m-0 p-0">
                  <Col className="m-0 p-0">
                    <Card
                      style={{
                        minHeight: '36rem',
                        borderRadius: '0.5rem',
                      }}>
                      <Row className="m-0 p-0">
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} className="m-0 p-0">
                          <Card style={{ height: '46.5rem', 'overflow-y': 'scroll' }}>
                            {!isEmpty(filters?.materialSelected) ? (
                              <ItemProgressCard
                                filters={filters}
                                setFilters={setFilters}
                                dateRange={dateRange}
                                materialTypesData={materialTypesData}
                                activeIndex={activeIndex}
                                setActiveIndex={setActiveIndex}
                              />
                            ) : (
                              <div
                                style={{ height: '36.5rem' }}
                                className="d-flex justify-content-center align-items-center">
                                <img src={Nodata} alt="no data" style={{ height: '30.5rem' }} />
                              </div>
                            )}
                          </Card>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} className="m-0 p-0">
                          <Card style={{ height: '46.5rem' }}>
                            <div
                              className="font-medium mt-5 ml-5"
                              style={{ fontSize: '1.077rem', color: '#5B7583' }}>
                              No. of {filters?.materialTypeSelected} Types{' '}
                            </div>
                            <div
                              style={{
                                height: '85%',
                                alignSelf: 'center',
                                width: '100%',
                              }}>
                              <ScanPieChart chartData={pieChartData} colorList={pieColors} />
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}
