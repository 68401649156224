import React from 'react';

export default function DetailsCard(props) {
  const { iconComponent, heading, content } = props;
  return (
    <div className="mt-2 mb-2 d-flex">
      <div className="mr-3 d-flex align-items-center">{iconComponent}</div>
      <div>
        <div className="font-semibold" style={{ fontSize: '0.769rem', color: '#000000' }}>
          {heading}
        </div>
        <div className="font-semibold" style={{ fontSize: '1.231rem', color: '#5B7583' }}>
          {content}
        </div>
      </div>
    </div>
  );
}
